export default {
  skills: [
    {
      title: "JavaScript",
      skills: [
        {
          title: "React"
        },
        {
          title: "Angular"
        },
        {
          title: "Nodejs"
        },
        {
          title: "Express"
        },
    ]
    }
  ]
}