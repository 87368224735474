export default {
  stats: [
    {
      title: "Components",
      value: 3456
    },
    {
      title: "Apps",
      value: 123
    },
    {
      title: "API Calls/ Day",
      value: 456000
    },
    {
      title: "Clients",
      value: 789
    },
  ]
}